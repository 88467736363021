import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { BasketContext } from '../../utils/BasketContext'
import BasketListItem from './basketListItem'

import * as BasketPopoverBannerStyles from "./basketPopoverBanner.module.css"

const BasketPopoverBanner = ({ noQuantitySelector }) => (
  <BasketContext.Consumer>
    {({ basketItems }) => {
      if (!basketItems || !basketItems.length) {
        return (
          <div className={BasketPopoverBannerStyles.container}>
            <p>
              Your shopping basket is empty
            </p>
            <AniLink
              to="/shop/all/"
              fade
              aria-label={`Continue shopping all collections`}
              className={BasketPopoverBannerStyles.redirectButton}
            >
              Continue shopping
            </AniLink>
          </div>
        )
      }

      const listItems = basketItems.map(
        item => <BasketListItem noQuantitySelector={noQuantitySelector} key={item._stripe_product_details.id} item={item} />
      )

      return (
        <div className={BasketPopoverBannerStyles.container}>
          <ul>
            {listItems}
            <li>
              <AniLink
                to="/basket/"
                fade
                aria-label={`Go to your shopping basket page`}
                className={BasketPopoverBannerStyles.redirectButton}
              >
                GO TO YOUR BASKET
              </AniLink>
            </li>
          </ul>
        </div>
      )
    }}
  </BasketContext.Consumer>
)

export default BasketPopoverBanner